<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_email_agreement_deadline')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="d-flex  mt-1 px-2 py-1 align-items-center">

                    <h5 class="mb-0">{{$t('label_email_agreement_deadline')}}</h5>
                    <b-button class="ml-auto" variant="outline-primary"  @click="$bvModal.show('shortcode-modal')">
                        {{$t('label_pattern_tags_show')}}
                    </b-button>

            </b-card-header>

            <b-card-body>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_subject')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_subject')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.email_agreement_deadline_subject"
                                    :placeholder="$t('label_subject')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_content')"
                            rules="required"
                            slim
                    >

                        <b-form-group
                                :label="$t('label_content')"
                        >

                            <b-form-textarea
                                    :placeholder="$t('label_content')"
                                    :state="getValidationState(validationProps)"
                                    rows="12"
                                    v-model="itemData.email_agreement_deadline_text"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <div class="d-flex mt-3 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ $t('label_save') }}
                        </b-button>
                        <b-button @click.prevent="$router.go(-1)"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  type="button"
                                  variant="outline-secondary"

                        >{{ $t('label_cancel') }}
                        </b-button>
                    </div>
                </validation-observer>
            </b-card-body>
        </b-card>

        <short-code-modal  type="agreement-deadline"></short-code-modal>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
        BFormGroup,
        BFormInput, BFormInvalidFeedback, BFormTextarea

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import  shortCodeModal from './../pattern/modal/shortCodeModal'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver, BFormTextarea,
            shortCodeModal
        },
        props: ['selectOption'],

        data() {
            return {
                PREFIX: 'general_setting',


                parentOption: {},

                itemData: {
                    email_agreement_deadline_subject: '',
                    email_agreement_deadline_text:'',
                }
            }

        },

        created() {

            this.getPageData();
        },

        methods: {

            getPageData(){

                this.async('get', '/general_settings', {params:{}}, function(res){
                    this.itemData.email_agreement_deadline_text = res.data.email_agreement_deadline_text;
                    this.itemData.email_agreement_deadline_subject = res.data.email_agreement_deadline_subject;
                });
            },

            onSubmit() {

                this.async('post', '/general_settings/email_agreement_deadline', this.itemData, function (resp) {
                   this.getPageData();
                });

            }

        }


    }
</script>

